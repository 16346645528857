function App() {
  var jwt = require("jsonwebtoken");

  var email = document.getElementsByClassName("email")[0].value;
  var rut = document.getElementsByClassName("rut")[0].value;
  var userid = document.getElementsByClassName("userid")[0].value;
  var wwwroot = document.getElementsByClassName("wwwroot")[0].value;

  var token = jwt.sign(
    {
      email: email,
      rut: rut,
      userid: userid,
    },
    "NLdpbrA67zpnfQE6gYWAKkcw",
    { expiresIn: 60 * 60 * 24 }
  );

  function renderElement(userid) {
    const urlTask = `https://tareas.bcnschool.net/tasks?id=`;
    const urlTaskNew = `http://task.bcnschool.net/?id=`;

    if (
      (userid == 100 ||
        userid == 6 ||
        userid == 40 ||
        userid == 58 ||
        userid == 16 ||
        userid == 29 ||
        userid == 68 ||
        userid == 11||
      userid == 19||
      //TI
      userid == 33|| //moises
      userid == 48|| //nomar
      userid == 54||
      userid == 84||
      userid == 104||
      userid == 110||
      userid == 111||
      userid == 156||
      userid == 163||
      userid == 168||
      userid == 173||
      userid == 174|| //Lesbia
      userid == 24  //Kisbel

      )
    ) {
      return (
        <>
          <li>
            <a href={`${urlTask}${userid}`} target="_blank">
              <i class="fa fa-vcard-o"></i>
              <span>Mis Tareas</span>
            </a>
          </li>

          <li>
            <a href={`${urlTaskNew}${token}`} target="_blank">
              <i class="fa fa-vcard-o"></i>
              <span>Mis Tareas (New Version)</span>
            </a>
          </li>
        </>
      );
    } else {
      return (
        <li>
          <a href={`${urlTask}${userid}`} target="_blank">
            <i class="fa fa-vcard-o"></i>
            <span>Mis Tareas</span>
          </a>
        </li>
      );
    }
  }

  return (
    <>
      <ul class="nav" ui-nav>
        {renderElement(userid)}
        <li>
          <a href={`https://talento.bcnschool.net/intranet-login/${token}`}target="_blank">
            <i class="fa fa-check"></i>
            <span>Evaluación de desempeño</span>
          </a>
        </li>
        <li>
          <a href={`${wwwroot}/Encuesta_Clima_Laboral/UserEncuesta/`} target="_blank">
            <i class="fa fa-group"></i>
            <span>Clima Laboral</span>
          </a>
        </li>
      </ul>
    </>
  );
}

export default App;